<template>
  <div v-if="upcomingGames.length" class="flex-container-base flex-column">
    <div
      class="card-base card-large"
      v-for="(game, index) in upcomingGames"
      :key="index"
    >
      <div class="icon-top-right">
        <i class="fa-solid fa-volleyball"></i>
      </div>
      <h3>
        {{ formatDate(game.date) }} - {{ game.time }} Uhr - Spiel
        {{ game.gameNr }}
      </h3>
      <div class="teams">
        <div class="team">
          <i :class="getIconClass(game.team1)"></i>
          <p>{{ game.team1 }}</p>
        </div>
        <p class="versus">VS</p>
        <div class="team">
          <i :class="getIconClass(game.team2)"></i>
          <p>{{ game.team2 }}</p>
        </div>
      </div>
      <p class="host">
        <strong>Gastgeber:</strong>
        {{ game.host === 1 ? game.team1 : game.team2 }}
      </p>
    </div>
  </div>
  <p v-else>No upcoming games</p>
</template>

<script>
export default {
  props: {
    games: {
      type: Array,
      required: true,
    },
  },
  computed: {
    upcomingGames() {
      const today = new Date();
      return this.games.filter((game) => {
        const gameDate = new Date(game.date);
        return gameDate >= today;
      });
    },
  },
  methods: {
    getIconClass(team) {
      return team === "DJK Saarbrücken-Rastpfuhl"
        ? "fas fa-trophy"
        : "fas fa-poo";
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.flex-container-base.flex-row {
  margin-top: 40px;
  text-align: center;
  padding: 0 15px;
}

.teams {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  position: relative;
}

.team {
  width: 40%;
}

.versus {
  width: 20%;
}

@media (max-width: 768px) {
  .teams {
    flex-direction: column;
    align-items: center;
  }

  .team,
  .versus {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .team {
    font-size: 1em;
  }

  .versus {
    font-size: 1em;
  }
}
</style>
