<template>
  <div v-if="previousGames.length" class="flex-container-base flex-column">
    <div
      class="card-base card-large"
      v-for="(game, index) in previousGames"
      :key="index"
    >
      <!-- Conditional Icon Display -->
      <div class="icon-top-right">
        <i
          :class="{
            'fa-solid fa-trophy': didWeWin(game),
            'fa-solid fa-poo': !didWeWin(game),
          }"
        ></i>
      </div>

      <h3>{{ formatDate(game.date) }} - Spiel {{ game.gameNr }}</h3>

      <!-- Teams and Sets Won Display -->
      <div class="teams">
        <div
          class="team"
          :class="{
            'our-team': game.team1 === ourTeam,
            'winning-team': isWinner(game, game.team1),
          }"
        >
          <p class="team-name">{{ game.team1 }}</p>
          <p class="sets-won" v-if="game.team1Wins !== null">
            Sätze: {{ game.team1Wins }}
          </p>
        </div>
        <p class="versus">VS</p>
        <div
          class="team"
          :class="{
            'our-team': game.team2 === ourTeam,
            'winning-team': isWinner(game, game.team2),
          }"
        >
          <p class="team-name">{{ game.team2 }}</p>
          <p class="sets-won" v-if="game.team2Wins !== null">
            Sätze: {{ game.team2Wins }}
          </p>
        </div>
      </div>

      <!-- Winner Display -->
      <p
        class="winner"
        :class="{
          won: didWeWin(game),
          lost: didWeLose(game),
        }"
        v-if="game.winner !== null"
      >
        <strong>{{
          didWeWin(game)
            ? "Gewonnen"
            : didWeLose(game)
            ? "Verloren"
            : "Unentschieden"
        }}</strong>
      </p>
      <p v-else class="winner"><strong>Result not available</strong></p>

      <!-- Set Results Card-based Display -->
      <div class="sets" v-if="game.results && game.results.sets">
        <h4>Satzergebnisse:</h4>
        <div class="small-cards">
          <div
            class="small-card"
            v-for="(set, setIndex) in Object.values(game.results.sets)"
            :key="setIndex"
            :class="{
              'team1-wins': set[0] > set[1],
              'team2-wins': set[1] > set[0],
              draw: set[0] === set[1],
            }"
          >
            <p class="set-number">Satz {{ setIndex + 1 }}</p>
            <div class="set-scores">
              <p class="score">{{ set[0] }} : {{ set[1] }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Additional Data Links (YouTube/Instagram) as Buttons with Logos -->
      <div
        v-if="
          game.results &&
          game.results.additional_data &&
          game.results.additional_data.length
        "
        class="additional-data"
      >
        <h4>Links:</h4>
        <div class="button-group">
          <button
            v-for="(data, dataIndex) in game.results.additional_data"
            :key="dataIndex"
            @click="openLink(data.url)"
            class="link-button"
          >
            <i
              :class="{
                'fab fa-youtube': data.type === 'YouTube',
                'fab fa-instagram': data.type === 'Instagram',
              }"
            ></i>
            {{ data.title }}
          </button>
        </div>
      </div>

      <!-- Separator between cards -->
      <hr
        class="game-card-separator"
        v-if="index !== previousGames.length - 1"
      />
    </div>
  </div>
  <p v-else>Noch keine Spiele</p>
</template>

<script>
export default {
  props: {
    games: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ourTeam: "DJK Saarbrücken-Rastpfuhl",
    };
  },
  computed: {
    previousGames() {
      const today = new Date();
      return this.games
        .filter((game) => {
          const gameDate = new Date(game.date);
          return gameDate < today;
        })
        .map((game) => {
          let team1Wins = null;
          let team2Wins = null;
          let winner = null;
          if (game.results && game.results.sets) {
            team1Wins = 0;
            team2Wins = 0;
            Object.values(game.results.sets).forEach((set) => {
              if (set[0] > set[1]) {
                team1Wins++;
              } else if (set[1] > set[0]) {
                team2Wins++;
              }
            });
            if (team1Wins > team2Wins) {
              winner = game.team1;
            } else if (team2Wins > team1Wins) {
              winner = game.team2;
            } else {
              winner = "Draw";
            }
          }
          return {
            ...game,
            team1Wins,
            team2Wins,
            winner,
          };
        });
    },
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    isWinner(game, team) {
      return game.winner === team;
    },
    didWeWin(game) {
      return game.winner === this.ourTeam;
    },
    didWeLose(game) {
      return (
        game.winner !== null &&
        game.winner !== this.ourTeam &&
        game.winner !== "Draw"
      );
    },
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.teams {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 20px 0;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
}

.team.winning-team {
  border: 2px solid var(--color-light-green);
}

.team-name {
  font-size: 1.1em;
  font-weight: bold;
  color: var(--color-text);
  text-align: center;
  margin: 5px 0;
}

.sets-won {
  font-size: 1em;
  color: var(--color-light-green);
  margin-top: 5px;
}

.versus {
  font-size: 1em;
  font-weight: bold;
  color: var(--color-secondary);
  margin: 0 10px;
  align-self: center;
  flex-shrink: 0;
}

.winner {
  font-size: 1.5em;
  margin-top: 15px;
  text-align: center;
}

.winner.won {
  color: var(--color-light-green);
}

.winner.lost {
  color: var(--color-light-red);
}

.set-number {
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.set-scores .score {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--color-text);
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.link-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-secondary);
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.9em;
}

.link-button i {
  font-size: 1.2em;
  vertical-align: middle;
}

.link-button:hover {
  background-color: var(--color-primary);
}

.game-card-separator {
  border: 0;
  height: 1px;
  margin: 20px 0;
}
</style>
