<template>
  <div class="flex-container-base flex-row">
    <div class="card-base card-small">
      <div class="icon-top-right">
        <i class="fa-solid fa-house"></i>
      </div>
      <div class="section-header">
        <h3>Hallendienst</h3>
      </div>
      <div class="small-cards">
        <div
          v-for="person in currentDuties.courtDuty"
          :key="person"
          class="small-card"
        >
          <i class="fas fa-user"></i>
          {{ person }}
        </div>
      </div>
    </div>
    <div class="card-base card-small">
      <div class="icon-top-right">
        <i class="fas fa-money-bill-wave"></i>
      </div>
      <div class="section-header">
        <h3>Kassendienst</h3>
      </div>
      <div class="small-cards">
        <div
          v-for="person in currentDuties.cashDuty"
          :key="person"
          class="small-card"
        >
          <i class="fas fa-user"></i>
          {{ person }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    duties: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentWeek() {
      const currentDate = new Date();
      const start = new Date(currentDate.getFullYear(), 0, 1);
      const diff = currentDate - start;
      const oneWeek = 1000 * 60 * 60 * 24 * 7;
      return Math.ceil(diff / oneWeek);
    },
    currentDuties() {
      return (
        this.duties.find(
          (duty) => duty.week - 1 === (this.currentWeek + 6) % this.duties.length
        ) || {}
      );
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  z-index: 2;
  position: relative;
}

li {
  background-color: #bdc3c7;
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
  font-size: 1.2em;
  transition: background-color 0.2s ease;
}

li:hover {
  background-color: #ecf0f1;
}

@media (max-width: 768px) {
  .flex-container-base.flex-column {
    padding: 0 15px;
  }

  .card-base.card-small {
    max-width: 100%;
    margin: 0 auto;
  }

  h3 {
    font-size: 1.8em;
  }

  .icon-top-right {
    font-size: 1.5em;
  }
}
</style>
