<template>
  <div id="app">
    <h1>DJK Saarbrücken-Rastpfuhl - Herren 1</h1>

    <div class="main-container">
      <!-- DutyDisplay Component -->
      <SectionContainer title="Mannschaftsdienste">
        <DutyDisplay :duties="duties" />
      </SectionContainer>

      <!-- GameDisplay Component -->
      <SectionContainer title="Anstehende Spiele">
        <GameDisplay :games="games" />
      </SectionContainer>

      <!-- PastGames Component -->
      <SectionContainer title="Vergangene Spiele">
        <PastGames :games="games" />
      </SectionContainer>
    </div>
  </div>
</template>

<script>
import DutyDisplay from "./components/DutyDisplay.vue";
import GameDisplay from "./components/GameDisplay.vue";
import SectionContainer from "./components/SectionContainer.vue";
import PastGames from "./components/PastGames.vue";
import { duties, games } from "./data";

export default {
  name: "App",
  components: {
    DutyDisplay,
    GameDisplay,
    PastGames,
    SectionContainer,
  },
  data() {
    return {
      duties,
      games,
    };
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  background-color: var(--color-background);
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: var(--color-text);
  margin-bottom: 40px;
  font-size: 2.5em;
  font-weight: bold;
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  align-items: center;
}

@media (max-width: 768px) {
  #app {
    padding: 10px;
  }

  h1 {
    font-size: 2em;
  }

  .main-container {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8em;
  }
}
</style>
